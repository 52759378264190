import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { Stack, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  main: {
    paddingTop: theme.spacing(4),
  },
}));

const MainLayout = (props) => {
  const { title, children, backButton = true, rightActions = [] } = props;
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            className={classes.backButton}
            onClick={() => navigate(-1)}
          >
            {backButton && <ArrowBack />}
          </IconButton>
          <Typography noWrap variant="h6" className={classes.title}>
            {title}
          </Typography>
          <Stack direction="row" spacing={2}>
            {rightActions}
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <div className={classes.main}>
        {children &&
          children.length &&
          children.filter(
            (child) =>
              !(
                child &&
                child.type &&
                child.type.options &&
                child.type.options.name === 'MuiBottomNavigation'
              ),
          )}
        {children && !children.length && children}
      </div>
      {children &&
        children.length &&
        children.filter(
          (child) =>
            child &&
            child.type &&
            child.type.options &&
            child.type.options.name === 'MuiBottomNavigation',
        )}
    </React.Fragment>
  );
};

export default MainLayout;
