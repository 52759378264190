import { useEffect, useState } from 'react';

type VXAPIArgs = {
  include_homedrop_order?: boolean;
  is_tpa_completed?: boolean;
  include_interaction_number?: boolean;
  type?: string;
  object?: string;
  number?: string;
  apiKey: string | undefined;
  url?: string | boolean;
  endpoint: string | undefined;
  group_by?: string;
  fields?: string | undefined;
  q?: string | undefined;
  other?: { [key: string]: string };
  per_page?: number;
  page?: number;
  sort?: string;
};

export const useVXAPI = <T>({
  url,
  endpoint,
  ...other
}: VXAPIArgs): {
  isLoading: boolean;
  data: T | undefined;
  error: string | undefined;
  resetData: () => void;
} => {
  const queryString = Object.entries(other)
    .map(([key, value]) => `${key === 'apiKey' ? 'api_key' : key}=${value}`)
    .join('&');
  const apiURL = `${url}${endpoint}?${queryString}`;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [fetchedData, setFetchedData] = useState<T>();
  useEffect(() => {
    if (url) {
      // Just before making the HTTP request, isLoading is set to true
      setIsLoading(true);
      // Make the HTTP request
      fetch(apiURL)
        .then((response) => {
          if (response.ok) return response.json();
          else throw Error(response.statusText);
        })
        .then((data) => {
          // As soon as available, update the state with returned data
          setFetchedData(data);
          setIsLoading(false);
          setError(undefined);
        })
        .catch((err) => {
          // Update the error state if one occours
          setError(err);
          setIsLoading(false);
          setFetchedData(undefined);
        });
    }
  }, [url, endpoint, apiURL]);

  return {
    isLoading: isLoading,
    data: fetchedData,
    error,
    resetData: () => setFetchedData(undefined),
  };
};
