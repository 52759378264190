import React from 'react';
import MainLayout from '../../components/MainLayout';
import InstructionData from '../../components/Instructions';

const InstructionPage = () => {
  return (
    <MainLayout title="Instructions" rightActions={[]}>
      <React.Fragment>
        <InstructionData />
      </React.Fragment>
    </MainLayout>
  );
};

export default InstructionPage;
