import React from 'react';
import { Alert, Divider, Container } from '@mui/material';
import step1 from './assets/step1.png';
import step2 from './assets/step2.png';
import step3 from './assets/step3.png';
import step4 from './assets/step4.png';
import step5v1 from './assets/step5-1.png';
import step5v2 from './assets/step5-2.png';
import step5v3 from './assets/step5-3.png';
import step5v4 from './assets/step5-4.png';
import step6 from './assets/step6.png';
import step7v1 from './assets/step7-1.png';
import step7v2 from './assets/step7-2.png';
import step8 from './assets/step8.png';
import step9 from './assets/step9.png';
import step10v1 from './assets/step10-1.png';
import step10v2 from './assets/step10-2.png';
import step11 from './assets/step11.png';
import step12 from './assets/step12.png';

const InstructionData = () => {
  return (
    <Container>
      <img src={step1} alt="step1" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Mount the cable tie by the upper entry of the fiber management tray, leaving enough room for
        the fiber cable connector to pass through.
        <br />
        <br />
        This will provide strain relief if the cable is stressed.
      </Alert>
      <Divider />

      <img src={step2} alt="step2" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Insert the fiber cable through the cable tie loop and coil it around the spools (see photo).
        <br />
        <br />
        Leave about 20cm of the fiber cable uncoiled and make sure to place it under the brackets
        illustrated by the red arrow.
      </Alert>
      <Divider />

      <img src={step3} alt="step3" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Mount the fiber management tray to the wall with minimum clearance of 10cm above the floor,
        using 3 screws as show on the photo.
        <br />
        <br />
        <strong>Caution</strong>: Overtightning the screws may result in damage.
      </Alert>
      <Divider />

      <img src={step4} alt="step4" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Firmly tighten the cable tie and cut of the ex- cess length using the wire cutter.
        <br />
        <br />
        An extra cable tie may be added to provide additional strain relief.
      </Alert>
      <Divider />

      <img src={step5v1} alt="step5-1" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Make sure to leave the LC connector outside of the fiber management tray (see photo)
      </Alert>
      <Divider />

      <img src={step5v2} alt="step5-2" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Carefully place the SDD onto the fiber management tray without sliding it to lock it.
      </Alert>
      <Divider />

      <img src={step5v3} alt="step5-3" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Tilt the SDD away from the wall at an angle of approximately 30 degrees to easily get access
        to the SFP.
      </Alert>
      <Divider />

      <img src={step5v4} alt="step5-4" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Connect the LC connector to the SFP.
        <br />
        <br />
        <strong>IMPORTANT:</strong> Make sure that the LC connector is fully seated into the SFP and
        that there is enough slack of fiber to allow the the SDD to close onto the fiber management
        tray.
      </Alert>
      <Divider />

      <img src={step6} alt="step6" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Tilt the SDD back without squeezing or pinching the fiber cable.
        <br />
        <br />
        Slide the SDD downwards about 5mm to close and lock it to the fiber management tray.
      </Alert>
      <Divider />

      <img src={step7v1} alt="step7-1" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Insert the lower end of the access cover plate into the two lower slots of the SDD.
      </Alert>
      <Divider />

      <img src={step7v2} alt="step7-2" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Tilt the access cover plate down onto the SDD and pinch it downwards/backwards until the
        upper latch can be inserted back into the SDD.
      </Alert>
      <Divider />

      <img src={step8} alt="step8" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Insert the locking screw and carefully tighten it until the flat head is in level with the
        SDD housing.
        <br />
        <br />
        <strong>CAUTION:</strong> Overtightening this screw may result in damage.
      </Alert>
      <Divider />

      <img src={step9} alt="step9" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Cover the flat head screw from Step 8 with the “WARRANTY VOID IF REMOVED” sticker.
      </Alert>
      <Divider />

      <img src={step10v1} alt="step10-1" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Hook the power supply cable onto the cable holder located on the lower right side of the
        fiber management tray.
      </Alert>
      <Divider />

      <img src={step10v2} alt="step10-2" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">Connect the power supply cable connector to the SDD.</Alert>
      <Divider />

      <img src={step11} alt="step11" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">
        Main installation of the SDD and fiber management tray is now done.
      </Alert>
      <Divider />

      <img src={step12} alt="step12" style={{ width: '100%', marginTop: '20px' }} />
      <Alert severity="info">Connect LAN cables.</Alert>
      <Divider />
    </Container>
  );
};

export default InstructionData;
