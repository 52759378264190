import React, { lazy, useState, useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';
import { CircularProgress, CssBaseline, Snackbar, SnackbarContent } from '@mui/material';
import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import { StoreProvider, useStore } from './store';

import Loader from './components/Loader';

import Home from './containers/Home';
import Login from './containers/Login';
import Profile from './containers/Profile';
import ForgotPassword from './containers/ForgotPassword';
import SetNewPassword from './containers/SetNewPassword';
import InstructionPage from './containers/InstructionPage';
import { handleAuth, handleVXAPIKratosErr } from './external_auth';

const Survey = lazy(() => componentLoader(() => import('./containers/Survey')));
const SurveyAgenda = lazy(() => componentLoader(() => import('./containers/SurveyAgenda')));
const SurveyList = lazy(() =>
  componentLoader(() => import('./containers/SurveyAgenda/SurveyList')),
);
const SurveyBulk = lazy(() => componentLoader(() => import('./containers/SurveyBulk')));
const SurveyAppointment = lazy(() =>
  componentLoader(() => import('./containers/SurveyAppointment')),
);
const Installation = lazy(() => componentLoader(() => import('./containers/Installation')));
const InstallationAgenda = lazy(() =>
  componentLoader(() => import('./containers/InstallationAgenda')),
);
const InstallationRecap = lazy(() =>
  componentLoader(() => import('./containers/InstallationRecap')),
);
const Connection = lazy(() => componentLoader(() => import('./containers/Connection')));
const ConnectionList = lazy(() => componentLoader(() => import('./containers/ConnectionList')));
const Sale = lazy(() => componentLoader(() => import('./containers/Sale')));
const SaleAgenda = lazy(() => componentLoader(() => import('./containers/SaleAgenda')));
const SaleList = lazy(() => componentLoader(() => import('./containers/SaleList')));
const Troubleshoot = lazy(() => componentLoader(() => import('./containers/Troubleshoot')));
const TroubleshootList = lazy(() => componentLoader(() => import('./containers/TroubleshootList')));

const originalFetch = window.fetch;
const options = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const useStyles = makeStyles()(() => ({
  circularProgress: {
    display: 'block !important',
    margin: '40px auto !important',
  },
  spinner: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)',
  },
}));

const App = () => {
  const { state, dispatch } = useStore();
  const { classes } = useStyles();
  const { baseURL, apiURL, apiKey, modules, external_auth, userData } = state;

  // Set data for Sentry
  const [config, setConfig] = useState('');
  const [release, setRelease] = useState('');

  // SET the local states
  const [online, setOnline] = useState(navigator.onLine);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // HANDLE KRATOS
  const kratosEnabled = external_auth != null;
  const publicKratosAPIUrl = kratosEnabled ? external_auth.api : '';
  useEffect(() => {
    if (kratosEnabled && apiURL != null) {
      handleAuth({ publicKratosAPIUrl }).then(() => {
        fetch(`${apiURL}api/api_key`, { ...options, credentials: 'include' })
          .then((response) => response.json())
          .then((data) => {
            if (data.api_key != null) {
              dispatch({ type: 'SET_API_KEY', apiKey: data.api_key });
              sessionStorage.setItem('apiKey', data.api_key);
            }
          });
      });
    }
  }, [apiURL, dispatch, kratosEnabled, publicKratosAPIUrl]);

  // ADD listeners to detect whether the connection is up or not
  useEffect(() => {
    const onlineCallback = () => setOnline(true);
    const offlineCallback = () => setOnline(false);

    window.addEventListener('online', onlineCallback);
    window.addEventListener('offline', offlineCallback);

    return () => {
      window.removeEventListener('online', onlineCallback);
      window.removeEventListener('offline', offlineCallback);
    };
  }, []);

  // LOAD release
  useEffect(() => {
    fetch('/release.json', { cache: 'no-store', ...options })
      .then((response) => response.json())
      .then((data) => {
        if (localStorage.getItem('release') !== data.version) {
          // SET the new release value
          localStorage.setItem('release', data.version);
          // RELOAD the page to get new chuncks
          location.reload();
        }

        dispatch({ type: 'SET_BUILD', build: data.build });
        dispatch({ type: 'SET_VERSION', version: data.version });
        setRelease(data);
        sessionStorage.setItem('release', JSON.stringify(data));
      });
  }, [dispatch]);

  // LOAD config
  useEffect(() => {
    fetch('/config.json', { cache: 'no-store', ...options })
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        sessionStorage.setItem('config', JSON.stringify(data));
        dispatch({ type: 'SET_API_URL', apiURL: data.api_url });
        dispatch({ type: 'SET_BASE_URL', baseURL: data.base_url });
        dispatch({ type: 'SET_PORTAL_URL', portalURL: data.portal_url });
        dispatch({ type: 'SET_MODULES', modules: data.modules });
        dispatch({ type: 'SET_LOCALE', locale: data.locale || 'en-GB' });

        // Overwrite window fetch to have a centralized
        // point responsible to handle API key expiry
        if (data.external_auth) {
          const api = data.external_auth.api;
          const app = data.external_auth.app;
          window.fetch = (url, options) =>
            originalFetch(url, options).then((response) => {
              if (response.status === 403 || response.status === 401) {
                response.json().then((data) => {
                  handleVXAPIKratosErr(
                    { publicKratosAPIUrl: api, publicKratosAppURL: app },
                    response.status,
                    data,
                  );
                });
              }

              return response;
            });
          dispatch({ type: 'SET_EXTERNAL_AUTH', external_auth: data.external_auth });
        } else {
          window.fetch = (url, options) =>
            originalFetch(url, options).then((response) => {
              if (response.status === 403) {
                sessionStorage.clear();
                dispatch({ type: 'SET_API_KEY', apiKey: undefined });
                setSnackbarOpen(true);
              }

              return response;
            });
        }
      });
  }, [dispatch]);

  // Once both config and release are ready start Sentry
  useEffect(() => {
    if (config && release && process.env.NODE_ENV !== 'development') {
      // Start Sentry to detect and trace errors
      startSentry();
    }
  }, [config, release]);

  // START Sentry if not on localhost
  const startSentry = async () => {
    Sentry.init({
      dsn: 'https://140200e65ed34d57892f3b95d86a2e79@o245893.ingest.sentry.io/4504871913783296',
      release: release.version,
      environment: config.environment || window.location.hostname,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  };

  // SET the API key
  useEffect(() => {
    const storedApiKey = sessionStorage.getItem('apiKey');
    if (apiKey === undefined && storedApiKey) {
      dispatch({ type: 'SET_API_KEY', apiKey: storedApiKey });
    }
  }, [dispatch, apiURL, apiKey]);

  // SET the User data in session storage if it exists
  useEffect(() => {
    const storedUserData = sessionStorage.getItem('userData');
    if (userData === undefined && storedUserData) {
      dispatch({ type: 'SET_USER_DATA', userData: JSON.parse(storedUserData) });
    }
  }, [userData, dispatch]);

  // SETUP the MUI theme
  const defaultTheme = useTheme();
  const vxTheme = createTheme({
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      primary: {
        main: '#5d22c7',
        dark: '#1b0095',
        light: '#9453fb',
      },
      secondary: {
        main: '#ae22c7',
        light: '#e35dfb',
        dark: '#7a0095',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '18px',
          },
        },
      },
    },
  });

  // DETECT application refresh actions
  const entries = performance.getEntriesByType('navigation');
  entries.forEach((entry) => {
    if (baseURL !== undefined) {
      if (entry.type === 'reload') {
        const target = entry.name.replace(window.location.origin, '');
        if (target !== baseURL) {
          window.location.replace(baseURL);
        }
      }
    }
  });

  // PREVENT application to be used when offline by rendering a "No Internet connection" message
  if (!online) {
    return <Loader message="No internet connection" classes={classes} />;
  }

  // SHOW a loader until the state is ready
  if (Object.keys(state).length === 0) {
    return <Loader message="Loading..." classes={classes} />;
  }

  // The application is loaded only when an API key exists,
  // the Login page is displayed otherwise. Need to also look for Kratos
  const loginRequiredNormal = !(apiKey && apiURL && baseURL && modules);
  const displayLogin = loginRequiredNormal && !kratosEnabled;

  if (displayLogin) {
    return (
      <ThemeProvider theme={vxTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="customer-password" element={<SetNewPassword />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route
              path={`${baseURL}`}
              element={
                <React.Fragment>
                  <Login />
                  <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={() => {
                      setSnackbarOpen(false);
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  >
                    <SnackbarContent message="Your session has expired please login." />
                  </Snackbar>
                </React.Fragment>
              }
            />
            <Route path="*" element={<Loader message="Loading..." classes={classes} />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    );
  } else if (kratosEnabled && apiKey == null) {
    return <Loader message="Loading..." classes={classes} />;
  }

  return (
    <ThemeProvider theme={vxTheme}>
      <BrowserRouter>
        <Suspense fallback={<CircularProgress className={classes.spinner} />}>
          <Routes>
            <Route path={`${baseURL}`} element={<Home />} />
            <Route path="survey/bulk" element={<SurveyBulk />} />
            <Route path="survey/:id" element={<Survey />} />
            <Route path="survey-appointment/:id" element={<SurveyAppointment />} />
            <Route path="survey" element={<SurveyAgenda />} />
            <Route path="survey-list" element={<SurveyList />} />
            <Route path="installation/:id" element={<Installation />} />
            <Route path="installation-recap/:id" element={<InstallationRecap />} />
            <Route path="installation" element={<InstallationAgenda />} />
            <Route path="connection/:object/:port" element={<Connection />} />
            <Route path="connection" element={<ConnectionList />} />
            <Route path="sale/:id" element={<Sale />} />
            <Route path="sale" element={<SaleAgenda />} />
            <Route path="sale-list" element={<SaleList />} />
            <Route path="troubleshoot/:id/:number" element={<Troubleshoot />} />
            <Route path="troubleshoot" element={<TroubleshootList />} />
            <Route path="instructions" element={<InstructionPage />} />
            <Route path="profile" element={<Profile />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <StrictMode>
  <StoreProvider>
    <CssBaseline />
    <App />
  </StoreProvider>,
  // </StrictMode>,
);

function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        console.log(error);
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500);
      });
  });
}
