import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useStore, StoreContext } from '../../store';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Input,
  Button,
  Dialog,
  Divider,
  Container,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material';
import Logo from '../../assets/vx-logo-color--positive.svg';

const currentUrl = document.location.href
  .split('/')
  .slice(0, -1)
  .concat('customer-password')
  .join('/');

const useStyles = makeStyles()((theme) => ({
  grid: {
    minHeight: '100vh',
  },
  input: {
    margin: 0,
    padding: 0,
    marginBottom: theme.spacing(3),
  },
  logo: {
    width: '120px',
    marginTop: theme.spacing(8),
  },
  space: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  errorSpace: {
    marginBottom: theme.spacing(3),
  },
}));

const ForgotPassword = () => {
  const navigate = useNavigate();

  // Define style
  const { classes } = useStyles();

  // Read from global store
  const { state }: StoreContext = useStore();
  const { apiURL, baseURL } = state;

  // Define the form
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Define local states
  const [open, setOpen] = useState({
    action: false,
    title: '',
    message: '',
  });

  const handleClose = () => {
    setOpen({
      action: false,
      title: '',
      message: '',
    });
    navigate(baseURL);
  };

  const onSubmit = (formData: { [key: string]: string }) => {
    fetch(
      `${apiURL}/api/user/password?username_or_email=${encodeURIComponent(
        formData.email,
      )}&url=${currentUrl}&usertype=user`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((response) => {
        if (response.status === 200) {
          setOpen({
            action: true,
            title: 'Confirm',
            message: 'Check your mailbox.',
          });
        } else {
          response.json().then((resp) => {
            setOpen({
              action: true,
              title: 'Error',
              message: `${resp.message}.`,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          alignContent="center"
          className={classes.grid}
        >
          <Typography variant="h5" align="center">
            <b>VX</b>MOBILE
          </Typography>
          <Container maxWidth="xs">
            <Divider variant="middle" className={classes.space} />
            <Container>
              <Typography paragraph align="center" className={classes.space}>
                Request password reset
              </Typography>
              <Controller
                name="email"
                control={control}
                rules={{ required: 'Email required' }}
                render={({ field }) => (
                  <React.Fragment>
                    <Input
                      {...field}
                      fullWidth
                      placeholder="Insert your email"
                      error={'email' in errors}
                      className={classes.input}
                    />
                    <FormHelperText className={classes.errorSpace}>
                      {errors.email?.message as string}
                    </FormHelperText>
                  </React.Fragment>
                )}
              />

              <Grid justifyContent="space-between" container>
                <Grid item>
                  <Button color="primary" variant="outlined" onClick={() => navigate(-1)}>
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit" color="primary" variant="contained">
                    Reset password
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Container>
          <img src={Logo} alt="logo" className={classes.logo} />
        </Grid>
      </form>
      <Dialog fullWidth open={open.action} onClose={handleClose}>
        <DialogTitle>{open.title}</DialogTitle>
        <DialogContent>{open.message}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Back to login
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ForgotPassword;
