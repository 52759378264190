export const transmit = (obj) => {
  if (typeof obj === 'object') {
    const msg = JSON.stringify(obj);
    // Throws the message to the shell app
    window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.cordova_iab.postMessage(msg);
  } else {
    console.error('The message input must be an object');
  }
};

export const formatAddress = ({
  street,
  building,
  postal_code,
  street_number,
  apartment_number,
}) => {
  return (
    `${street}${street_number ? `, ${street_number}` : ''}` +
    `${building ? `, ${building}` : ''}` +
    `${apartment_number ? `, ${apartment_number}` : ''}` +
    `${postal_code ? `, ${postal_code}` : ''}`
  );
};
