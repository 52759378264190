import React, { useContext, useReducer, createContext } from 'react';

import { reducer } from './reducer';

// TYPES IMPORT
import { ConnectionResearch, ServingArea } from './containers/ConnectionList/types';
import { SaleResearch } from './containers/SaleList/types';
import { Modules, UserData } from './containers/Home/types';
import { TroubleshootResearch } from './containers/TroubleshootList/types';
import { SaleData } from './containers/Sale/types';

// WIP - baseURL mandatory in type State
export const initialState = {
  baseURL: '',
  modules: {
    survey: {
      enabled: false,
      default_workflow_name: '',
      survey_email_template: '',
      roles: [],
    },
    connection: {
      enabled: false,
      roles: [],
    },
    installation: {
      enabled: false,
      roles: [],
      default_workflow_name: '',
      new_appointment: {
        enabled: false,
        date_selection: false,
        right_away_scheduling: false,
        workflow_name: '',
      },
    },
    troubleshoot: {
      enabled: false,
      roles: [],
    },
    sale: {
      enabled: false,
      roles: [],
      show_class_code: false,
      show_sales_area: false,
      show_all_mdu: false,
    },
  },
};

type ExternalAuth = {
  api: string;
  app: string;
};

// All variables of the store defined that could be used by Dispatch for the actions
type State = {
  apiKey?: string;
  external_auth?: ExternalAuth | null;
  userData?: UserData;
  apiURL?: string;
  baseURL: string;
  portalURL?: string;
  modules: Modules;
  locale?: string;
  build?: string;
  version?: string;
  saleResearch?: SaleResearch;
  // appointment?:;
  // surveyAppointment?:;
  // survey?:;
  // surveyResearch?:;
  // researchScope?:;
  // surveyResearchResults?:;
  // connection?:;
  connectionResearch?: ConnectionResearch;
  fiberServingAreas?: Array<ServingArea>;
  sale?: SaleData;
  // saleResearch?:;
  // selectedAgendaDate?:;
  // selectedSurveyAgendaDate?:;
  troubleshootResearch?: TroubleshootResearch;
};

// interface DispatchAction extends Partial<State> {
//   type: string;
// }

// Extend State base to define DispatchAction adding type string that is mandatory
// type DispatchAction = StateBase & {
//   type: string;
// };

type DispatchAction =
  | {
      type: 'SET_API_KEY';
      apiKey: string;
    }
  | {
      type: 'SET_EXTERNAL_AUTH';
      external_auth: ExternalAuth | null;
    }
  | {
      type: 'SET_USER_DATA';
      userData: UserData;
    }
  | {
      type: 'SET_API_URL';
      apiURL: string;
    }
  | {
      type: 'SET_BASE_URL';
      baseURL: string;
    }
  | {
      type: 'SET_PORTAL_URL';
      portalURL: string;
    }
  | {
      type: 'SET_MODULES';
      modules: Modules;
    }
  | {
      type: 'SET_LOCALE';
      locale: string;
    }
  | {
      type: 'SET_BUILD';
      build: string;
    }
  | {
      type: 'SET_VERSION';
      version: string;
    }
  | {
      type: 'SET_CURRENT_CONNECTION_RESEARCH';
      connectionResearch: ConnectionResearch;
    }
  | {
      type: 'SET_CURRENT_TROUBLESHOOT_RESEARCH';
      troubleshootResearch: TroubleshootResearch;
    }
  | {
      type: 'SET_FIBER_SERVING_AREAS';
      fiberServingAreas?: Array<ServingArea>;
    }
  | {
      type: 'SET_CURRENT_SALE_RESEARCH';
      saleResearch: SaleResearch;
    }
  | {
      type: 'CLEAR_CURRENT_CONNECTION_RESEARCH';
    }
  | { type: 'CLEAR_CURRENT_TROUBLESHOOT_RESEARCH' }
  | {
      type: 'SET_CURRENT_CONNECTION';
      connection: {
        object?: { [index: string]: string };
        port?: { [index: string]: string };
      };
    }
  | {
      type: 'CLEAR_CURRENT_SALE_RESEARCH';
    }
  | {
      type: 'CLEAR_STATE';
    };

export type StoreContext = {
  state: State;
  dispatch: React.Dispatch<DispatchAction>;
};

// Create the store context
const StoreContext = createContext<StoreContext>({
  // The default value that you want the context to have when there is no
  // matching context provider in the tree above the component that reads context.
  state: initialState,
  dispatch: () => ({ type: 'SET_API_KEY', apiKey: sessionStorage.getItem('apiKey') }),
});

// Create the Provider enabling to share context
// to every component in the React App tree
export function StoreProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
}

// Export a useStore hook. This will be used from
// whatever component to access the global state
export const useStore = () => useContext(StoreContext);
