import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Button,
  Dialog,
  Divider,
  Container,
  TextField,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  DialogContentText,
} from '@mui/material';
import { Create as CreateIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';

import { StoreContext, useStore } from '../../store';
import { transmit } from '../../utils.js';
import MainLayout from '../../components/MainLayout';
import { kratosLogout } from '../../external_auth';

type FormData = {
  probeNumber: string;
};

const Profile = () => {
  const navigate = useNavigate();

  // Read from global store
  const { state, dispatch }: StoreContext = useStore();

  const { baseURL, userData, build, version } = state;

  // Define the form
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  // Define the local state
  const [isLogoutModalOpen, setIsLogoutModalOpen] = React.useState(false);
  const [isEditProbeModalOpen, setIsEditProbeModalOpen] = React.useState(false);
  const [probeNumber, setProbeNumber] = React.useState(localStorage.getItem('probeNumber') || '');

  const openLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  const openEditProbeModal = () => {
    setIsEditProbeModalOpen(true);
  };

  const closeEditProbeModal = () => {
    setIsEditProbeModalOpen(false);
  };

  const confirmNewProbe = (formData: FormData) => {
    if (
      formData.probeNumber &&
      formData.probeNumber.length === 9 &&
      parseInt(formData.probeNumber)
    ) {
      // Update the local state
      setProbeNumber(formData.probeNumber);
      // Store the probe number in the local storage
      localStorage.setItem('probeNumber', formData.probeNumber);
      // Close the modal
      closeEditProbeModal();
    } else {
      // Generate an error if the probe number is invalid
      setError('probeNumber', {
        type: 'manual',
        message: 'Insert a valid 9 digits probe number!',
      });
    }
  };

  const logout = () => {
    // Throws a message to the shell app
    transmit({ logout: true });

    if (state.external_auth != null) {
      kratosLogout({ publicKratosAPIUrl: state.external_auth.api });
      return;
    }

    sessionStorage.clear();
    // Come back to the main URL even if we are running
    // this HTML5 application alone outside the shell app
    dispatch({ type: 'CLEAR_STATE' });

    navigate(baseURL);
  };

  return (
    <MainLayout title="Profile" rightActions={[]}>
      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
        alignContent="center"
      >
        <AccountCircleIcon sx={{ fontSize: 60 }} color="disabled" />
        <Typography paragraph align="center" color="textSecondary">
          Personal details and general info
        </Typography>

        <Container maxWidth="sm">
          <Divider variant="middle" sx={{ marginBottom: '32px' }} />
          <Container>
            <TextField
              fullWidth
              margin="normal"
              label="First Name"
              value={userData?.first_name || ''}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Last Name"
              value={userData?.last_name || ''}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              label="Email"
              margin="normal"
              value={userData?.email || ''}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Phone number"
              value={userData?.mobile_number || ''}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Probe number"
              value={probeNumber || ''}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={openEditProbeModal}>
                      <CreateIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="VXMobile build"
              value={build || ''}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="VXMobile version"
              value={version || ''}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={openLogoutModal}
              sx={{ marginTop: '32px' }}
            >
              Logout
            </Button>
          </Container>
        </Container>
      </Grid>
      <Dialog open={isLogoutModalOpen} onClose={closeLogoutModal}>
        <DialogTitle>Do you really want to log out?</DialogTitle>
        <DialogActions>
          <Button onClick={closeLogoutModal} color="primary">
            No
          </Button>
          <Button onClick={logout} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isEditProbeModalOpen} onClose={closeEditProbeModal}>
        <form onSubmit={handleSubmit(confirmNewProbe)}>
          <DialogTitle>Edit Probe number</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To properly perform home drop installations, please enter the correct probe number.
            </DialogContentText>
            <Controller
              name="probeNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoFocus
                  fullWidth
                  margin="dense"
                  label="Probe number"
                  placeholder="000000XXX"
                  defaultValue={probeNumber}
                  error={errors.probeNumber !== undefined}
                  helperText={errors.probeNumber && (errors.probeNumber.message as string)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">VXPROBE</InputAdornment>,
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeEditProbeModal} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <br />
      <br />
    </MainLayout>
  );
};

export default Profile;
