import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Grid,
  Input,
  Button,
  Divider,
  Container,
  IconButton,
  Typography,
  FormHelperText,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';

import { StoreContext, useStore } from '../../store';
import Logo from './../../assets/vx-logo-color--positive.svg';

const useStyles = makeStyles()((theme) => ({
  space: {
    marginBottom: theme.spacing(3),
  },
  margin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

const Login = () => {
  // Access to the theme
  const theme = useTheme();

  // Define style
  const { classes } = useStyles();

  // Read from global store
  const { state, dispatch }: StoreContext = useStore();
  const { apiURL, build, version } = state;

  // Define the local state
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loginError, setLoginError] = React.useState({ status: false, msg: '' });

  // Define the form
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleClickShowPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClose = () => {
    // Close login error modal
    setOpen(false);
    setLoginError({ status: false, msg: '' });
    dispatch({ type: 'CLEAR_STATE' });
  };

  // Retrieving api key token and user data
  const onSubmit = (formData: { [key: string]: string }) => {
    const opts = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(`${formData.username}:${formData.password}`)}`,
      },
    };

    // Perform the authentication
    fetch(`${apiURL}api/api_key`, opts).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch({ type: 'SET_API_KEY', apiKey: data.api_key });
          sessionStorage.setItem('apiKey', data.api_key);
        });
      } else {
        if (response.status === 401) {
          setOpen(true);
          setLoginError({
            status: true,
            msg: 'The email address or password you entered is not valid.',
          });
        } else {
          setOpen(true);
          setLoginError({ status: true, msg: response.statusText });
        }
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
        alignContent="center"
        sx={{
          minHeight: '100vh',
        }}
      >
        <Typography align="center" variant="h5">
          <b>VX</b>MOBILE
        </Typography>
        <Container maxWidth="xs">
          <Divider variant="middle" className={classes.margin} />
          <Container>
            <Controller
              name="username"
              control={control}
              rules={{ required: 'Username required' }}
              render={({ field }) => (
                <React.Fragment>
                  <Input {...field} fullWidth placeholder="Username" error={'username' in errors} />
                  <FormHelperText className={classes.space}>
                    {errors.username?.message as string}
                  </FormHelperText>
                </React.Fragment>
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{ required: 'Password required' }}
              render={({ field }) => (
                <React.Fragment>
                  <Input
                    {...field}
                    fullWidth
                    placeholder="Password"
                    error={'password' in errors}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText className={classes.space}>
                    {errors.password?.message as string}
                  </FormHelperText>
                </React.Fragment>
              )}
            />

            <Button fullWidth type="submit" color="primary" variant="contained">
              LOGIN
            </Button>
            <Typography align="center" color="primary" className={classes.margin}>
              <Link to={`${state.baseURL}forgot-password`}>Forgot password?</Link>
            </Typography>
          </Container>
        </Container>
        <img
          src={Logo}
          alt="logo"
          style={{
            width: '130px',
            marginTop: theme.spacing(8),
          }}
        />
        <Typography
          align="center"
          sx={{
            fontSize: '12px',
            color: '#b7b7b7',
            marginTop: '15px',
          }}
          className={classes.margin}
        >
          {`BUILD: ${build} | VERSION: ${version}`}
        </Typography>
        {loginError.status && (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="form-dialog-title">Error</DialogTitle>
            <DialogContent>
              <DialogContentText>{loginError.msg}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Grid>
    </form>
  );
};

export default Login;
