import { initialState } from './store';

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'SET_API_KEY':
      return {
        ...state,
        apiKey: action.apiKey,
      };
    case 'SET_EXTERNAL_AUTH':
      return {
        ...state,
        external_auth: action.external_auth,
      };
    case 'CLEAR_STATE':
      const { apiKey, userData, ...loggedOutState } = state; // eslint-disable-line
      return loggedOutState;
    case 'SET_USER_DATA':
      return {
        ...state,
        userData: action.userData,
      };
    case 'SET_API_URL':
      return {
        ...state,
        apiURL: action.apiURL,
      };
    case 'SET_BASE_URL':
      return {
        ...state,
        baseURL: action.baseURL,
      };
    case 'SET_PORTAL_URL':
      return {
        ...state,
        portalURL: action.portalURL,
      };
    // Set the modules (e.g., Survey, Installation) to show in the application
    case 'SET_MODULES':
      return {
        ...state,
        modules: action.modules,
      };
    case 'SET_LOCALE':
      return {
        ...state,
        locale: action.locale,
      };
    // release build
    case 'SET_BUILD':
      return {
        ...state,
        build: action.build,
      };
    // release version
    case 'SET_VERSION':
      return {
        ...state,
        version: action.version,
      };
    // Set the appointment selected from the installation appointment agenda
    case 'SET_CURRENT_APPOINTMENT':
      return {
        ...state,
        appointment: action.appointment,
      };
    // Set the appointment selected from the survey appointment agenda
    case 'SET_CURRENT_SURVEY_APPOINTMENT':
      return {
        ...state,
        surveyAppointment: action.surveyAppointment,
      };
    // Set the survey selected from the survey list
    case 'SET_CURRENT_SURVEY':
      return {
        ...state,
        survey: action.survey,
      };
    // Set the research text string used in the survey form
    case 'SET_CURRENT_SURVEY_RESEARCH':
      return {
        ...state,
        surveyResearch: action.surveyResearch,
        researchScope: action.researchScope,
      };
    // Set the results of a certain research made in the survey form
    case 'SET_CURRENT_SURVEY_RESEARCH_RESULTS':
      return {
        ...state,
        surveyResearchResults: action.surveyResearchResults,
      };
    // Set the connection selected from the connection list
    case 'SET_CURRENT_CONNECTION':
      return {
        ...state,
        connection: action.connection,
      };
    // Set the research performed in the connection search form
    case 'SET_CURRENT_CONNECTION_RESEARCH':
      return {
        ...state,
        connectionResearch: action.connectionResearch,
      };
    // Set the research performed in the connection search form
    case 'SET_FIBER_SERVING_AREAS':
      return {
        ...state,
        fiberServingAreas: action.fiberServingAreas,
      };
    // Clear the research performed in the connection search form
    case 'CLEAR_CURRENT_CONNECTION_RESEARCH':
      const { connectionResearch, ...newState } = state; // eslint-disable-line
      return newState;
    // Set the research performed in the sale search form
    case 'SET_CURRENT_SALE_RESEARCH':
      return {
        ...state,
        saleResearch: action.saleResearch,
      };
    // Clear the research performed in the sale search form
    case 'CLEAR_CURRENT_SALE_RESEARCH': {
      const { saleResearch, ...newState } = state; // eslint-disable-line
      return newState;
    }
    // Set the selected date in the installation appointment agenda
    case 'SET_CURRENT_AGENDA_DATE': {
      return {
        ...state,
        selectedAgendaDate: action.selectedDate,
      };
    }
    // Set the selected date in the survey appointment agenda
    case 'SET_CURRENT_SURVEY_AGENDA_DATE': {
      return {
        ...state,
        selectedSurveyAgendaDate: action.selectedDate,
      };
    }
    // Clear the selected date in the installation agenda
    case 'CLEAR_CURRENT_AGENDA_DATE': {
      const { selectedAgendaDate, ...newState } = state; // eslint-disable-line
      return newState;
    }
    // Set the research performed in the troubleshoot search form
    case 'SET_CURRENT_TROUBLESHOOT_RESEARCH': {
      return {
        ...state,
        troubleshootResearch: action.troubleshootResearch,
      };
    }
    // Clear the research performed in the troubleshoot search form
    case 'CLEAR_CURRENT_TROUBLESHOOT_RESEARCH': {
      const { troubleshootResearch, ...newState } = state; // eslint-disable-line
      return newState;
    }
    default:
      return state;
  }
}
