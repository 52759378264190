const externalLogin = (externalLogin = false) => ({ externalLogin });
const whoAmIRoute = (publicKratosURL) => {
  return `${publicKratosURL}/sessions/whoami`;
};
const logoutRoute = (publicKratosAPIUrl) => {
  return `${publicKratosAPIUrl}/self-service/logout/browser`;
};
const login = (publicKratosAPIUrl) => {
  window.location.replace(
    `${publicKratosAPIUrl}/self-service/login/browser?return_to=${window.location.href}`,
  );
};
const verify = (publicKratosAPIUrl) => {
  window.location.replace(
    `${publicKratosAPIUrl}/self-service/verification/browser?return_to=${window.location.href}`,
  );
};

export const kratosLogin = ({ publicKratosAPIUrl }) => login(publicKratosAPIUrl);

export const kratosLogout = ({ publicKratosAPIUrl }) => {
  fetch(logoutRoute(publicKratosAPIUrl), { credentials: 'include' })
    .then((data) => data.json())
    .then((res) => {
      if (res.error != null) {
        login(publicKratosAPIUrl);
        return;
      }
      window.location.replace(res.logout_url);
    })
    .catch(() => {
      login(publicKratosAPIUrl);
    });
};

export const handleAuth = ({ publicKratosAPIUrl }) => {
  return new Promise((resolve) => {
    if (publicKratosAPIUrl == null || publicKratosAPIUrl === '') {
      resolve(externalLogin(false));
      return;
    }

    fetch(whoAmIRoute(publicKratosAPIUrl), { credentials: 'include' })
      .then((data) => data.json())
      .then((kratos) => {
        if (kratos.error != null) {
          login(publicKratosAPIUrl);
          return;
        }
        resolve(externalLogin(true));
      })
      .catch(() => {
        login(publicKratosAPIUrl);
      });
  });
};

export const handleVXAPIKratosErr = ({ publicKratosAPIUrl, publicKratosAppURL }, code, data) => {
  if (code !== 403) {
    login(publicKratosAPIUrl);
    return;
  }

  switch (data.message) {
    case 'EmailNotVerified':
      alert('You need to verify your email, click OK and we will redirect you');
      verify(publicKratosAPIUrl);
      break;
    case 'UserNotReady':
      alert(
        'Your login is valid but your user is not ready yet. Please contact VXFiber to complete your registration',
      );
      window.location.replace(publicKratosAppURL);
      break;
    default:
      window.location.replace(publicKratosAppURL);
      break;
  }
};
