import { Grid, Typography, CircularProgress } from '@mui/material';

import Logo from '../../assets/vx-logo-color--positive.svg';

function Loader(props) {
  const { message, classes } = props;

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      alignContent="center"
      style={{ minHeight: '100vh' }}
    >
      <img src={Logo} alt="logo" style={{ width: 200 }} />

      <CircularProgress className={classes.circularProgress} />

      <Typography variant="subtitle1" gutterBottom>
        {message}
      </Typography>
    </Grid>
  );
}

export default Loader;
