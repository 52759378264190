import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Dialog,
  Button,
  Divider,
  Container,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  DialogContentText,
} from '@mui/material';
import {
  Build as BuildIcon,
  Storage as StorageIcon,
  ArrowForward as ArrowForwardIcon,
  AccountCircle as AccountCircleIcon,
  BusinessCenter as BusinessCenterIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  QueryStats as QueryStatsIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { StoreContext, useStore } from '../../store';
import { useVXAPI } from '../../hooks/useVXAPI';
import MainLayout from '../../components/MainLayout';
import Logo from '../../assets/vx-logo-color--positive.svg';

import { Module, Modules, UserData } from './types';

const useStyles = makeStyles()((theme) => ({
  logo: {
    width: '120px',
    marginTop: theme.spacing(2),
  },
  spinner: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)',
  },
}));

const StyledListItemText = styled(ListItemText)(() => ({
  '& .MuiListItemText-primary': {
    fontSize: '20px',
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: '50px',
  height: '50px',
  background: theme.palette.primary.main,
}));

const StyledListItemAvatar = styled(ListItemAvatar)(() => ({
  minWidth: '64px',
}));

const Home = () => {
  // Read from global store
  const { state, dispatch }: StoreContext = useStore();
  const { apiURL, baseURL, apiKey, modules, userData } = state;

  const { classes } = useStyles();
  const navigate = useNavigate();

  // Set the local states
  const [open, setOpen] = useState(false);
  const [loginError, setLoginError] = useState({ status: false, msg: '' });

  // Define which are the roles enabled to login by retrieving them from the app configuration file
  const loginEnabledRoles = [
    // Make the array unique
    ...new Set(
      // Accumulate all the roles enabled in the different modules
      Object.values(modules as Modules).reduce<string[]>(
        (acc, cur: Module) => [...acc, ...(cur.roles || [])],
        [],
      ),
    ),
  ];

  // Compute module visibility
  let showSurvey, showInstallation, showConnection, showTroubleshoot, showSale;
  if (userData) {
    showSurvey = modules?.survey.enabled && modules.survey.roles.indexOf(userData.role) >= 0;
    showInstallation =
      modules?.installation.enabled && modules?.installation.roles.indexOf(userData.role) >= 0;
    showConnection =
      modules?.connection.enabled && modules?.connection.roles.indexOf(userData.role) >= 0;
    showSale = modules?.sale.enabled && modules?.sale.roles.indexOf(userData.role) >= 0;
    showTroubleshoot =
      modules?.troubleshoot.enabled && modules?.troubleshoot.roles.indexOf(userData.role) >= 0;
  }

  // Close login error modal
  const handleClose = () => {
    setOpen(false);
    setLoginError({ status: false, msg: '' });

    sessionStorage.clear();

    dispatch({ type: 'CLEAR_STATE' });
    navigate(baseURL);
  };

  // Retrieve user data if they do not exist
  const { isLoading: isMyProfileLoading, data: myProfileData } = useVXAPI<UserData>({
    apiKey,
    url: userData === undefined && apiURL,
    endpoint: 'api/my_profile',
    fields: 'userid,username,first_name,last_name',
  });

  const {
    isLoading: isUserDataLoading,
    data: newUserData,
    error,
  } = useVXAPI<UserData>({
    apiKey,
    url: myProfileData && apiURL,
    endpoint: 'api/user',
    q: myProfileData && `id:${myProfileData.userid}`,
    fields: 'email,mobile_number',
  });

  useEffect(() => {
    if (userData === undefined && myProfileData !== undefined && newUserData !== undefined) {
      const finalData = myProfileData || userData;

      if (finalData.role) {
        if (loginEnabledRoles.indexOf(finalData.role) >= 0) {
          const email = newUserData.data && newUserData.data[0].email;
          const mobile_number = newUserData.data && newUserData.data[0].mobile_number;
          const finalUserData = { ...myProfileData, email, mobile_number };

          dispatch({ type: 'SET_USER_DATA', userData: finalUserData });
          sessionStorage.setItem('userData', JSON.stringify(finalUserData));
        } else {
          setLoginError({
            status: true,
            msg: `You cannot access with ${finalData.role} credential`,
          });
          setOpen(true);
        }
      }
    }
  }, [dispatch, userData, myProfileData, newUserData, error]);

  const rightActions = [
    <IconButton
      key="userIcon"
      edge="end"
      onClick={() => navigate(`${baseURL}profile`)}
      color="inherit"
    >
      <AccountCircleIcon />
    </IconButton>,
  ];

  return (
    <MainLayout title="VXMobile" backButton={false} rightActions={rightActions}>
      {isMyProfileLoading || isUserDataLoading ? (
        <CircularProgress className={classes.spinner} />
      ) : (
        <React.Fragment>
          <Container
            maxWidth="sm"
            sx={{
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '20px',
            }}
          >
            <Typography align="center" variant="h5">
              {userData && `Hi ${userData.first_name}!`}
            </Typography>
            <Typography paragraph align="center" color="textSecondary">
              What are you up to?
            </Typography>
            <Divider />
          </Container>
          <Container maxWidth="sm">
            <List>
              {showSurvey && (
                <React.Fragment>
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end">
                        <ArrowForwardIcon color="primary" />
                      </IconButton>
                    }
                    onClick={() => navigate(`${baseURL}survey`)}
                  >
                    <StyledListItemAvatar>
                      <StyledAvatar>
                        <AssignmentTurnedInIcon />
                      </StyledAvatar>
                    </StyledListItemAvatar>
                    <StyledListItemText
                      primary="Survey"
                      secondary="Carry out unsurveyed premises"
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )}
              {showConnection && (
                <React.Fragment>
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end">
                        <ArrowForwardIcon color="primary" />
                      </IconButton>
                    }
                    onClick={() => navigate(`${baseURL}connection`)}
                  >
                    <StyledListItemAvatar>
                      <StyledAvatar>
                        <StorageIcon />
                      </StyledAvatar>
                    </StyledListItemAvatar>
                    <StyledListItemText primary="Connection" secondary="Assign ports to objects" />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )}
              {showInstallation && (
                <React.Fragment>
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end">
                        <ArrowForwardIcon color="primary" />
                      </IconButton>
                    }
                    onClick={() => navigate(`${baseURL}installation`)}
                  >
                    <StyledListItemAvatar>
                      <StyledAvatar>
                        <BuildIcon />
                      </StyledAvatar>
                    </StyledListItemAvatar>
                    <StyledListItemText
                      primary="Installation"
                      secondary="Perform your daily schedule"
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )}
              {showTroubleshoot && (
                <React.Fragment>
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end">
                        <ArrowForwardIcon color="primary" />
                      </IconButton>
                    }
                    onClick={() => navigate(`${baseURL}troubleshoot`)}
                  >
                    <StyledListItemAvatar>
                      <StyledAvatar>
                        <QueryStatsIcon />
                      </StyledAvatar>
                    </StyledListItemAvatar>
                    <StyledListItemText
                      primary="Troubleshoot"
                      secondary="Get insights about object issues"
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )}
              {showSale && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end">
                      <ArrowForwardIcon color="primary" />
                    </IconButton>
                  }
                  onClick={() => navigate(`${baseURL}sale`)}
                >
                  <StyledListItemAvatar>
                    <StyledAvatar>
                      <BusinessCenterIcon />
                    </StyledAvatar>
                  </StyledListItemAvatar>
                  <StyledListItemText primary="Sale" secondary="Sell new connections" />
                </ListItem>
              )}
            </List>
          </Container>
          <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: '20px' }}>
            <img src={Logo} alt="logo" className={classes.logo} />
          </Container>
        </React.Fragment>
      )}
      {loginError.status && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title">Error</DialogTitle>
          <DialogContent>
            <DialogContentText>{loginError.msg}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <br />
      <br />
    </MainLayout>
  );
};

export default Home;
