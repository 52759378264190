import React from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../store';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  Input,
  Grid,
  Container,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import Logo from '../../assets/vx-logo-color--positive.svg';

type Data = {
  new_password: string;
  password_confirmation: string;
};

function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&'); // eslint-disable-line
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const useStyles = makeStyles()((theme) => ({
  grid: {
    minHeight: '100vh',
  },
  input: {
    margin: 0,
    padding: 0,
    marginBottom: theme.spacing(3),
  },
  button: {},
  logo: {
    width: '120px',
    marginTop: theme.spacing(8),
  },
  space: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

const SetNewPassword = () => {
  const { state } = useStore();
  const { apiURL } = state;
  const { classes } = useStyles();
  const recoveryCode = getParameterByName('recovery_code');
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Data>();
  const [open, setOpen] = React.useState({ action: false, title: '', message: '' });

  const handleClose = () => {
    setOpen({ action: false, title: '', message: '' });
  };

  const onSubmit = (formData: Data) => {
    const password = { password: formData.new_password };
    fetch(`${apiURL}/api/user/password?recovery_code=${recoveryCode}&usertype=user`, {
      method: 'PUT',
      body: JSON.stringify(password),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (response.status === 200) {
          setOpen({ action: true, title: 'Confirm', message: 'Your new password has been saved.' });
        } else {
          response.json().then((resp) => {
            setOpen({ action: true, title: 'Error', message: `${resp.message}.` });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          alignContent="center"
          className={classes.grid}
        >
          <Typography align="center" variant="h5">
            <b>VX</b>MOBILE
          </Typography>
          <Container maxWidth="xs">
            <Divider variant="middle" className={classes.space} />
            <Container>
              <Typography paragraph align="center" className={classes.space}>
                Request password reset
              </Typography>
              <Input
                fullWidth
                type="text"
                className={classes.input}
                placeholder="New password"
                error={'new_password' in errors}
                {...register('new_password', { required: true })}
              />

              <Input
                fullWidth
                type="password"
                className={classes.input}
                placeholder="Confirm new password"
                error={'password_confirmation' in errors}
                {...register('password_confirmation', {
                  validate: (value) => value === watch('new_password'),
                })}
              />
              <Grid justifyContent="center" container>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  Save new password
                </Button>
              </Grid>
            </Container>
          </Container>
          <img src={Logo} alt="logo" className={classes.logo} />
        </Grid>
      </form>
      <Dialog fullWidth open={open.action} onClose={handleClose}>
        <DialogTitle>{open.title}</DialogTitle>
        <DialogContent>{open.message}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SetNewPassword;
